import moment from 'moment-timezone';
import store from '@/store/store'

/**
 * Formats a Unix timestamp into a human-readable date string, considering the company's timezone.
 *
 * @param {number} unixTime - The Unix timestamp (in seconds) to be formatted.
 * @param {string} [format='MM-DD HH:mm'] - The date format string (optional, default is 'MM-DD HH:mm').
 * @returns {string} - The formatted date string in the company's timezone.
 *
 * @example
 * // Returns "09-26 12:34" if the timezone corresponds to the formatted time.
 * const formattedDate = formatDateToTimezoneCompany(1695736445);
 */
export function formatDateToTimezoneCompany(unixTime, format = 'MM-DD HH:mm') {
  const UTC = 'UTC'
  const timezoneKey = store.getters[`login/getTimezoneKey`] || UTC
  const timezoneMapping = store.getters[`properties/timezoneMapping`] || UTC
  const timezone = timezoneMapping[timezoneKey] || UTC

  const trackTime = new Date(1970, 0, 1);
  trackTime.setUTCSeconds(unixTime);

  return moment(trackTime).tz(timezone).format(format);
}
